<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="视频列表" /> -->
        <el-button type="success" @click="addVideo" class="turnBule">添加公告</el-button>
        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="id" label="序号" min-width="60" />
                <el-table-column prop="noticeTitle" label="公告名称" min-width="60" />
                <el-table-column prop="regDate" label="缩略图" min-width="60">
                    <template slot-scope="scope">
                        <img :src="scope.row.noticeImg" width="40" height="40" />
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="60" />
                <el-table-column prop="noticePlay" label="浏览数量" min-width="60" />
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="editorFormClick(scope.row)">编辑</el-button>
                        <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryPxNoticePage" />
        </TableContainer>


        <!-- <Tinymce :height="200" v-model="tiny.value"></Tinymce> -->

        <!-- <button @click="buttonClick">提交</button> -->




    </section>
</template>

<script>
import Tinymce from "./tinymce";
import {
    bxUser,
    addPxNotice,
    queryPxNotice,
    updatePxNotice,
    queryPxNoticePage,
    delPxNotice

} from '@api/bx/api_contentManagement';
export default {
    components: { Tinymce },
    data() {

        return {
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },
            total: 0,
            tableData: [],
            typeList: [],
            dicCode: null,
            addCode: null,
            addItem: {},
            dicName: '',
            isEditor: false,
            editorRow: {},
            parmas: {
                zhName: '',//中文名称
                enName: '',//英文名称
                materialHistory: '',//原料含量
                waterCosmetics: '',//复配百分比
                residencyCosmetics: '',//实际成分含量
                saltRule: '',//使用目的
                no: '',//原料序号
                saltRule: '',//化妆品安全技术规范位置
            },
            text: '',
            myConfig: {
                autoHeightEnabled: false, // 编辑器不自动被内容撑高
                initialFrameHeight: 500, // 初始容器高度
                initialFrameWidth: '100%', // 初始容器宽度
                UEDITOR_HOME_URL: '/UEditor/',
                serverUrl: ''
            },
        }
    },
    created() {
        this.queryPxNoticePage()

    },
    methods: {
        addVideo() {
            this.$router.push(
                {
                    path: '/bxPxNoticeMenu/addNotice',
                    query: {
                        editor: '0',

                    }
                }
            )

        },
        // 保存富文本内容-保存字符串
        add() {
            const params = `this.tiny?.value`
        },
        edit() {
            // 接口回显保存html
            // this.tiny.value = res.data|| ''
        },
        queryPxNoticePage() {
            queryPxNoticePage(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },
        editorFormClick(row) {
            this.$router.push(
                {
                    path: '/bxPxNoticeMenu/addNotice',
                    query: {
                        editor: '1',
                        id:row.id
                    }
                }
            )
        },

        buttonClick() {
            //首次提交
            //     addPxNotice({ 
            //         noticeTitle: '测试',
            //         noticeContent:this.tiny.value,
            //         noticeImg:''

            //  }).then(res => {
            //             this.$message.success('上传成功')

            //         })
            //编辑提交
            updatePxNotice({
                noticeTitle: '编辑测试',
                noticeContent: this.tiny.value,
                id: 836,
                noticeImg: ''

            }).then(res => {
                this.$message.success('上传成功')

            })
            // console.log(this.tiny.value)


        },
        //文章复现
        queryPxNotice() {
            // console.log(localStorage.getItem('userId').data)
            // return
            queryPxNotice({
                // userId: localStorage.getItem('userId').data,
            }).then(res => {
                console.log(res)
                // this.$message.success('上传成功')
                // this.bxUser()
                this.tiny.value = res[1].noticeContent
            })
            console.log(this.tiny.value)
        },


        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

        // 会员管理列表
        bxUser() {
            bxUser(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },

        //解绑
        dissolutionFormClick(row) {
            this.$confirm('是否脱离关系?', '提示', {
                confirmButtonText: '脱离关系',
                cancelButtonText: '关闭',
                type: 'warning'
            }).then(() => {
                bxUserUnbinding({ userId: row.userId }).then(res => {
                    this.$message.success('解绑成功')
                    this.bxUser()
                })
            })
        },
        //表格删除
        delteFormClick(row) {
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delPxNotice({ id: row.id }).then(res => {
                	this.queryPxNoticePage()
                })
            })

        }


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

