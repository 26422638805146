import { render, staticRenderFns } from "./dcPxNoticeList.vue?vue&type=template&id=7275ad6f&scoped=true&"
import script from "./dcPxNoticeList.vue?vue&type=script&lang=js&"
export * from "./dcPxNoticeList.vue?vue&type=script&lang=js&"
import style0 from "./dcPxNoticeList.vue?vue&type=style&index=0&id=7275ad6f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7275ad6f",
  null
  
)

export default component.exports